import React from "react"

export const TitleUnderlineSmsOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="104" height="9" viewBox="0 0 104 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3352 9C17.1851 8.16452 1.44113 9.09727 0.296765 8.84548C-0.637501 8.50786 0.855536 2.5622 1.72275 1.68666C2.02672 1.37193 2.37986 1.32616 3.18897 1.46922C4.4719 1.73818 21.0607 1.20598 21.0607 1.20598C21.2797 1.38909 21.9369 0.77107 22.0576 0.994246C22.4375 1.51499 49.3256 0.805402 51.6188 0.77679C53.009 0.759622 51.9094 1.11441 53.5454 0.971352C57.0322 0.685228 64.6315 0.959909 67.2733 0.633728C69.3877 0.364771 69.8437 0.353335 71.8731 0.570789C73.9473 0.765353 84.9752 0.484949 86.2358 0.0672084C86.5442 -0.0472411 86.7812 -0.00718032 86.7812 0.124437C86.7812 0.153049 94.5548 0.250328 94.5906 0.353332C94.6487 0.484949 94.8856 0.547892 95.1046 0.456332C95.5114 0.318993 103.786 -0.178864 103.915 0.204542C104.322 1.30326 103.182 5.98424 102.221 7.12301C101.238 8.3419 95.8377 6.97424 91.4972 7.70671L71.2876 7.78111L66.956 8.2389C66.4598 7.84405 64.551 8.83976 63.3977 8.13017C62.9105 7.84977 59.4684 7.80971 58.7085 8.10728C58.0469 8.33618 40.4389 7.75821 38.633 8.55363C35.4144 7.88982 22.3615 8.83977 19.3352 9Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineSmsTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="149" height="9" viewBox="0 0 149 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.7014 9C24.6209 8.16452 2.06469 9.09727 0.425173 8.84548C-0.913343 8.50786 1.22572 2.5622 2.46817 1.68666C2.90367 1.37192 3.40961 1.32616 4.56881 1.46922C6.40686 1.73818 30.1735 1.20598 30.1735 1.20598C30.4873 1.38909 31.4288 0.77107 31.6017 0.994246C32.1461 1.51499 70.6684 0.805402 73.9539 0.77679C75.9456 0.759622 74.3701 1.11441 76.7141 0.971352C81.7096 0.685228 92.597 0.959909 96.382 0.633728C99.4113 0.364771 100.065 0.353335 102.972 0.570789C105.944 0.765353 121.743 0.484949 123.549 0.0672084C123.991 -0.0472411 124.331 -0.00718032 124.331 0.124437C124.331 0.153049 135.468 0.250328 135.519 0.353332C135.602 0.484949 135.942 0.547892 136.256 0.456332C136.838 0.318993 148.693 -0.178864 148.879 0.204542C149.461 1.30326 147.828 5.98424 146.451 7.12301C145.042 8.3419 137.306 6.97424 131.087 7.70671L102.133 7.78111L95.9273 8.2389C95.2164 7.84405 92.4817 8.83976 90.8294 8.13017C90.1313 7.84977 85.1999 7.80971 84.1112 8.10728C83.1634 8.33618 57.9365 7.75821 55.3491 8.55363C50.738 7.88982 32.0372 8.83977 27.7014 9Z" fill="#FFBA00" />
    </svg>
  </span>
)