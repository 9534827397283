import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import banca from "./images/banca.png"
import { Link } from "gatsby"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

const Industries = ({ data }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const dotsClassDefault = "slick-dots" // clase por defecto de la libreria
  const dotsClassCustom = "slick-dots-slider-tabs"

  const activateSlider = {
    customPaging: function (i) {
      const tab = i => data[i].tab

      return <button>{tab(i)}</button>
    },
    slidesToShow: 1,
    infinite: true,
    initialSlide: 0,
    dots: true,
    speed: 500,
    dotsClass: `${dotsClassDefault} ${dotsClassCustom}`,
    afterChange: function (index) {
      const dots = document.querySelector(`.${dotsClassCustom}`)
      dots.scrollLeft = 100 * index
    },
  }



  //estado para el texto de la caja en desktop
  const [text, settext] = useState([
     {
      tab: "01. Banca y finanzas",
      title: "Campañas SMS para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Envía alertas automáticas de seguridad, como notificaciones de actividad inusual o autenticación de dos factores (2FA), para proteger las cuentas de tus clientes. Además, proporciona notificaciones en tiempo real sobre transacciones, como confirmaciones de pagos y depósitos.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Conoce más de SMS para Banca y Finanzas",
    },

  ])
  const support = (event, text) => {
    event.preventDefault()
    settext([text])
  }


  //Activar estilo para primer boton al cargar la pagina
  useLayoutEffect(() => {
    var header = document.getElementById("myDIVindustries-channels")
    var btns = header.getElementsByClassName("container-industries-channels-navbar-menu-button")
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("container-industries-channels-navbar-menu-button-active")
        current[0].className = current[0].className.replace("container-industries-channels-navbar-menu-button-active", "")
        this.className += "container-industries-channels-navbar-menu-button-active"
      })
    }
  })

  return (
    <section id="myDIVindustries-channels" className="container-industries-channels">
      <p className="container-industries-channels-title">
      <TitleUnderlineOne underline="Automatizamos las" />   conversaciones de las empresas en diferentes rubros
      </p>
      <section className="container-industries-channels-navbar">
        <ul className="container-industries-channels-navbar-menu">
          {data.map((elemento, i) => {
            return (
              <li key={i}>
                <button
                  className={`container-industries-channels-navbar-menu-button ${elemento.active}`}
                  onClick={event => {
                    support(event, elemento)
                  }}
                >
                  {elemento.tab}
                </button>
              </li>
            )
          })}
        </ul>
        <div className="container-industries-channels-navbar-info">
          <div>
            <p className="container-industries-channels-navbar-info-title">{text[0].title}</p>
            <p className="container-industries-channels-navbar-info-text">{text[0].text}</p>
            <Link className="container-industries-channels-navbar-info-link" href={text[0].link}>
              {text[0].textLink}
            </Link>
          </div>
          <img src={text[0].image} alt="" />
        </div>
      </section>


      <div ref={fromRef} className="container-industries-channels-silder">
        <Slider {...activateSlider} ref={sliderRef}>
          {data.map((selectElement, i) => {
            return (
              <div key={i} className="container-industries-channels-silder-item">
                <div>
                  <p className="container-industries-channels-silder-item-title">
                    {selectElement.title}
                  </p>
                  <p className="container-industries-channels-silder-item-text">
                    {selectElement.text}
                  </p>
                  <Link className="container-industries-channels-silder-item-link" href={selectElement.link}>
                    {selectElement.textLink}
                  </Link>
                </div>
                <img src={selectElement.image} alt="" />
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Industries
