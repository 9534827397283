import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="251" height="8" viewBox="0 0 251 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6648 8C41.4755 7.25735 3.47811 8.08646 0.716231 7.86265C-1.53858 7.56254 2.0648 2.27751 4.15779 1.49925C4.89141 1.21949 5.74371 1.17881 7.69644 1.30597C10.7928 1.54505 50.8292 1.07198 50.8292 1.07198C51.3579 1.23475 52.9438 0.685396 53.2351 0.883775C54.1521 1.34666 119.045 0.715913 124.58 0.69048C127.935 0.67522 125.281 0.99059 129.23 0.863424C137.645 0.609092 155.986 0.853252 162.362 0.563314C167.465 0.324241 168.565 0.314075 173.463 0.507368C178.469 0.680314 205.084 0.431066 208.127 0.0597408C208.871 -0.0419921 209.443 -0.00638251 209.443 0.11061C209.443 0.136044 228.204 0.222513 228.291 0.314073C228.431 0.431066 229.003 0.487015 229.531 0.405629C230.513 0.283549 250.483 -0.15899 250.796 0.181815C251.777 1.15845 249.026 5.31932 246.707 6.33157C244.333 7.41502 231.301 6.19932 220.825 6.85041L172.05 6.91654L161.596 7.32347C160.398 6.97249 155.791 7.85756 153.008 7.22682C151.832 6.97757 143.525 6.94196 141.691 7.20647C140.094 7.40994 97.5977 6.89618 93.2391 7.60323C85.4714 7.01318 53.9687 7.85757 46.6648 8Z" fill="#FFA700"/>
      </svg>
      
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="178" height="8" viewBox="0 0 178 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.093 8C29.4129 7.25735 2.46655 8.08646 0.507925 7.86265C-1.09111 7.56254 1.46428 2.27751 2.94855 1.49925C3.46881 1.21949 4.07323 1.17881 5.45804 1.30597C7.65384 1.54505 36.0462 1.07198 36.0462 1.07198C36.4211 1.23475 37.5458 0.685396 37.7524 0.883775C38.4027 1.34666 84.4227 0.715913 88.3476 0.69048C90.727 0.67522 88.8449 0.99059 91.6451 0.863424C97.6128 0.609092 110.619 0.853252 115.141 0.563314C118.76 0.324241 119.54 0.314075 123.014 0.507368C126.564 0.680314 145.438 0.431066 147.596 0.0597408C148.124 -0.0419921 148.529 -0.00638251 148.529 0.11061C148.529 0.136044 161.834 0.222513 161.895 0.314073C161.995 0.431066 162.4 0.487015 162.775 0.405629C163.471 0.283549 177.633 -0.15899 177.855 0.181815C178.551 1.15845 176.6 5.31932 174.955 6.33157C173.272 7.41502 164.03 6.19932 156.601 6.85041L122.011 6.91654L114.598 7.32347C113.748 6.97249 110.482 7.85756 108.508 7.22682C107.674 6.97757 101.782 6.94196 100.482 7.20647C99.3495 7.40994 69.2127 6.89618 66.1218 7.60323C60.6132 7.01318 38.2726 7.85757 33.093 8Z" fill="#FFA700"/>
      </svg>
  </span>
)