import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="310" height="11" viewBox="0 0 310 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.7871 9.34651C50.357 8.56369 4.69723 10.4099 1.43907 10.2503C-1.45177 9.98119 0.580592 4.11399 2.82688 3.20092C3.62329 2.87638 4.66265 2.79344 7.14714 2.89249C9.32616 2.9785 24.3411 2.57401 60.6656 1.68654C61.3775 1.85844 63.1739 1.22737 63.6004 1.44218C63.9255 1.56702 69.2014 1.5381 77.0442 1.45797C85.8445 1.36699 109.801 1.03432 121.616 0.911647C139.054 0.723094 132.495 0.744122 152.458 0.604626C156.634 0.574505 153.373 0.934117 158.282 0.796841C168.754 0.509552 191.648 0.862723 199.573 0.59884C205.93 0.386717 207.315 0.37849 213.424 0.633881C218.312 0.837077 252.797 0.971284 256.689 0.637895C257.627 0.537578 258.339 0.592214 258.318 0.720511C258.726 0.734187 281.679 1.21616 281.78 1.32287C281.943 1.4523 282.635 1.52915 283.307 1.44953C284.53 1.31189 309.483 1.3483 309.827 1.73011C310.798 2.83051 306.554 7.3049 303.553 8.36409C300.409 9.47265 284.938 7.83067 271.654 8.32399C271.654 8.32399 267.907 8.27299 262.285 8.18811C207.394 7.40337 209.266 7.72201 198.794 8.01488C197.309 7.61487 191.702 8.54075 188.223 7.85485C186.779 7.57235 176.574 7.50951 174.291 7.77463C173.802 7.83304 170.176 7.83305 164.9 7.81731C159.645 7.8017 152.74 7.76476 145.753 7.77757C142.249 7.78391 138.725 7.78455 135.385 7.80306C132.024 7.81587 128.825 7.85759 125.994 7.90157C120.31 7.98941 116.072 8.11408 114.767 8.31822C104.403 7.70718 67.6888 9.01662 57.7871 9.34651Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="129" height="12" viewBox="0 0 129 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.6928 10.0457C21.633 9.28976 2.80566 11.3012 1.46324 11.1534C0.272626 10.8948 1.13159 5.02035 2.0608 4.09916C2.39026 3.77175 2.81897 3.68505 3.84271 3.77511C4.74058 3.85323 10.9311 3.39439 25.9071 2.37545C26.1999 2.54477 26.9427 1.90721 27.1177 2.12047C27.2512 2.24413 29.426 2.19611 32.6591 2.08759C36.2868 1.96475 46.1629 1.54537 51.0335 1.37993C58.2218 1.12826 55.5181 1.17303 63.7473 0.961273C65.4687 0.916037 64.1231 1.28745 66.1474 1.1324C70.4649 0.80721 79.9001 1.0775 83.1679 0.784934C85.7888 0.549804 86.3598 0.536563 88.8771 0.769833C90.891 0.955332 105.105 0.964703 106.711 0.61723C107.098 0.513521 107.391 0.565577 107.382 0.693947C107.55 0.706148 117.009 1.10502 117.051 1.21136C117.117 1.3402 117.402 1.41454 117.68 1.33249C118.184 1.19042 128.469 1.13651 128.61 1.51707C129.006 2.61393 127.241 7.10358 126 8.1736C124.7 9.29352 118.329 7.70758 112.851 8.24898C112.851 8.24898 111.307 8.21155 108.99 8.14702C86.3669 7.561 87.1374 7.87285 82.8199 8.20363C82.2095 7.809 79.8948 8.75516 78.4633 8.08187C77.8689 7.80461 73.6627 7.7787 72.7207 8.05209C72.5188 8.11227 71.0243 8.1254 68.8498 8.12876C66.6836 8.13217 63.8375 8.12023 60.9575 8.15833C59.5133 8.17736 58.0608 8.19075 56.6837 8.22135C55.2982 8.24632 53.9798 8.29962 52.8124 8.35386C50.4693 8.46227 48.7221 8.60227 48.1835 8.81114C43.9136 8.23763 28.7755 9.67994 24.6928 10.0457Z" fill="#FFBA00" />
    </svg>
  </span>
)
